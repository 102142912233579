main
{
	width: 100%;
}

.MuiAccordion-root
{
	box-shadow: 0 0 0 0 !important;
	border-radius: 0 !important;
	border: 1px solid lightgray;
}

.MuiList-root div:last-child
{
	border-bottom: none;
}

.MuiAccordionSummary-root .MuiSvgIcon-root 
{
	transform: rotate(90deg)
}

.MuiDialog-paper img
{
	max-width: 100%;
}
