html,
body {
  background-color: #f5f5f5;
}

.header-nav > a {
  border-bottom: 1px solid rgb(118, 118, 118);
  text-decoration: none;
  padding-bottom: 1px;
  line-height: 1.6rem;
}
.header-nav > a:hover {
  border-bottom: 2px solid rgba(0, 0, 0, 1);
  padding-bottom: 0;
}

/* customed checkbox error color. */
.MuiFormControlLabel-root.Mui-error {
  color: rgb(211, 47, 47);
}
.MuiFormControlLabel-root.Mui-error a {
  color: rgb(211, 47, 47);
  text-decoration-color: rgb(211, 47, 47);
}
.MuiFormControlLabel-root.Mui-error span {
  border-color: rgb(211, 47, 47);
}
